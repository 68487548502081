import { Route, RouteProps } from 'react-router-dom';

import { ComponentType } from 'react';

interface ExternalRouteProps extends RouteProps {
  component: ComponentType<any>;
}

function ExternalRoute({ component: Component, ...rest }: ExternalRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  );
}

export default ExternalRoute;
