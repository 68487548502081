import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  INotificationContext,
  INotificationProps,
  INotificationQueueItem,
  NotificationPermissionEnum,
  NotificationProviderProps,
} from './types';

const handlerPermission = {
  granted: NotificationPermissionEnum.GRANTED,
  denied: NotificationPermissionEnum.DENIED,
  default: NotificationPermissionEnum.DEFAULT,
  not_supported: NotificationPermissionEnum.NOT_SUPPORTED,
};

export const NotificationContext = createContext({} as INotificationContext);
type NotificationPermission = 'granted' | 'default' | 'denied';

function NotificationProvider({ children }:NotificationProviderProps) {
  const [permission, setPermission] = useState<NotificationPermissionEnum>(
    NotificationPermissionEnum.DEFAULT,
  );

  const [notificationQueue, setNotificationQueue] = useState<Array<INotificationQueueItem>>([]);

  useEffect(() => {
    if (!('Notification' in window)) {
      setPermission(handlerPermission.not_supported);
    } else {
      Notification.requestPermission().then((permissionSelected: NotificationPermission) => {
        setPermission(handlerPermission[permissionSelected]);
      });
    }
  }, []);

  const handleAddNotification = useCallback((
    id: string,
    title: string,
    options?: INotificationProps,
    openUrl?: string,
  ) => {
    setNotificationQueue((oldState) => {
      if (oldState.some(({ id: existentId }) => existentId === id)) return oldState;

      return [({
        id, title, options, showed: false, openUrl,
      } as INotificationQueueItem), ...oldState];
    });
  }, [setNotificationQueue]);

  const handleUpdateNotification = useCallback((id: string) => {
    setNotificationQueue((oldState) => {
      const [item] = oldState.filter(({ id: existentId }) => existentId === id);

      if (!item) return oldState;

      const otherNotifications = oldState.filter(({ id: existentId }) => existentId !== id);

      return [...otherNotifications, { ...item, showed: true }];
    });
  }, [setNotificationQueue]);

  useEffect(() => {
    const [filtered] = notificationQueue.filter(({ showed }) => !showed);

    if (filtered) {
      const {
        id, title, options, openUrl,
      } = filtered;

      (() => {
        const notification = new Notification(title, options);
        notification.addEventListener('click', (e: Event) => {
          e.preventDefault();
          window.open(openUrl, '_blank');
        });
      })();

      handleUpdateNotification(id);
    }
  }, [notificationQueue, handleUpdateNotification]);

  const value = useMemo(() => ({
    permission,
    notificationQueue,
    addNotification: handleAddNotification,
    updateNotification: handleUpdateNotification,
  }), [permission, notificationQueue, handleAddNotification, handleUpdateNotification]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
