/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueueEntryDto } from '../api/queue-api';
import { QueueTab } from '../../common/constants';

interface CurrentAccountState {
  activeQueueEntry?: QueueEntryDto;
  activeQueueTab?: QueueTab;
}

const initialState: CurrentAccountState = {
  activeQueueEntry: undefined,
  activeQueueTab: QueueTab.Queue,
};

const trackerboardSlice = createSlice({
  name: 'trackerboard',
  initialState,
  reducers: {
    setActiveQueueEntry(state, action: PayloadAction<QueueEntryDto>) {
      state.activeQueueEntry = action.payload;
    },
    setActiveQueueTab(state, action: PayloadAction<QueueTab>) {
      state.activeQueueTab = action.payload;
    },
    clearActiveQueueEntry(state) {
      state.activeQueueEntry = undefined;
    },
  },
});

export const {
  setActiveQueueEntry,
  setActiveQueueTab,
  clearActiveQueueEntry,
} = trackerboardSlice.actions;

export default trackerboardSlice.reducer;
