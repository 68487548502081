import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import * as Sentry from '@sentry/react';

import { ZoomClient, ZoomContext, ZoomContextState } from './ZoomContext';
import useTrackEvent, { Events } from '../../hooks/useTrackEvent';

interface ZoomProviderProps {
}

function ZoomProvider(props: React.PropsWithChildren<ZoomProviderProps>) {
  const {
    children,
  } = props;

  const zoomElRef = useRef<HTMLDivElement>();
  const [client, setClient] = useState<ZoomClient>();

  const trackUserEndsACall = useTrackEvent(Events.UserEndsACall);

  const initZoomClient = useCallback(async () => {
    try {
      // Remove existing client
      if (zoomElRef.current) {
        zoomElRef.current.remove();
        zoomElRef.current = undefined;
        setClient(undefined);
      }

      // Create root DOM element
      const el = document.createElement('div');
      document.body.appendChild(el);
      el.className = 'zoom-meeting';

      // Hide the unclickable zoom meeting element above the main content
      el.style.height = '0';

      zoomElRef.current = el;

      const zoomClient: ZoomClient = ZoomMtgEmbedded.createClient();

      const handleClick = () => {
        zoomClient.endMeeting();
        window.location.reload();
        trackUserEndsACall();
      };

      await zoomClient.init({
        debug: true,
        zoomAppRoot: el,
        language: 'en-US',
        customize: {
          meetingInfo: ['topic', 'host', 'participant', 'dc', 'enctype'],
          video: {
            popper: {
              disableDraggable: false,
            },
            isResizable: true,
            viewSizes: {
              // Respect 16:9 aspect ratio
              default: {
                width: 640,
                height: 360,
              },
            },
          },
          chat: {
            popper: {
              disableDraggable: false,
            },
          },
          toolbar: {
            buttons: [
              {
                text: 'Leave Meeting',
                className: 'leave-meeting-li',
                onClick: () => {
                  handleClick();
                },
              },
            ],
          },
        },
      });

      setClient(zoomClient);

      return zoomClient;
    } catch (error) {
      Sentry.captureException(error);
    }
    return undefined;
  }, [trackUserEndsACall]);

  const value = useMemo<ZoomContextState>(() => ({
    initZoomClient,
    client,
  }), [client, initZoomClient]);

  return (
    <ZoomContext.Provider value={value}>
      {children}
    </ZoomContext.Provider>
  );
}

export default ZoomProvider;
