import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    html {
      font-size: 62.5%;
      height: 100%;
    }
    body {
      background-color: ${theme.colors.white};
      font-family: ${theme.fonts.family.default};
      font-size: ${theme.fonts.sizes.medium};
      height: 100%;
    }
    ol,
    li {
      list-style: none;
    }

    button {
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }

    #root {
      height: 100%;
      .Toastify__toast--error {
        font-size: 1.6rem;
      }
      .Toastify__toast--success {
        font-size: 1.6rem;
      }
      .Toastify__toast--warning {
        font-size: 1.6rem;
      }
    }

    .zoom-meeting {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }

    #transition-popper {
      z-index: 3;
    }

    li.leave-meeting-li:hover {
      background-color: red;
    }

    div[role='dialog'] {
      z-index: 3;
    }

    .jss58 {
      display: none;
    }
    iframe{
      display: none;
    }
  `}
`;
