/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveQueueEntry } from './trackerboard';
import { BotEntrypointDto } from '../api/chat-api';

interface CurrentMayaEntrypoint {
  entrypoint: BotEntrypointDto;
  accountId: string;
  userId: string;
}

interface ChatState {
  currentMayaEntrypoint?: CurrentMayaEntrypoint | null;
}

const initialState: ChatState = {
  currentMayaEntrypoint: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentMayaEntrypoint(state, action: PayloadAction<CurrentMayaEntrypoint | null>) {
      state.currentMayaEntrypoint = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(setActiveQueueEntry, (state) => {
      state.currentMayaEntrypoint = null;
    });
  },
});

export const {
  setCurrentMayaEntrypoint,
} = chatSlice.actions;

export default chatSlice.reducer;
