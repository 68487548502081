import { ReactNode } from 'react';

export enum NotificationPermissionEnum {
  GRANTED = 'granted',
  DENIED = 'denied',
  DEFAULT = 'default',
  NOT_SUPPORTED = 'not_supported',
}

export interface INotificationProps {
  body?: string,
  data?: string,
  icon?: string
}

export interface NotificationProviderProps {
  children: ReactNode;
}

export interface INotificationContext {
  permission: NotificationPermissionEnum;
  addNotification: (id: string, title: string, options?: INotificationProps, openUrl?: string) => (
    void
  );
  updateNotification: (id: string) => void;
  notificationQueue: Array<INotificationQueueItem>;
}

export interface INotificationQueueItem {
  id: string,
  options?: INotificationProps;
  title: string;
  showed: boolean;
  openUrl?: string,
}
