import { ILoadingProps } from './Loading';
import loader from '../../../assets/icons/loader.png';
import * as S from './styles';

function Loading({ color, height, width }: ILoadingProps) {
  return (
    <S.Wrapper data-testid="component-loading">
      <S.Loader
        src={loader}
        color={color}
        width={width}
        height={height}
      />
    </S.Wrapper>
  );
}

export default Loading;
