import { useEffect, useState } from 'react';

export default function usePrevious<T>(value: T): T | null {
  const [current, setCurrent] = useState(value);
  const [previous, setPrevious] = useState<T | null>(null);

  useEffect(() => {
    if (value !== current) {
      setPrevious(current);
      setCurrent(value);
    }
  }, [current, value]);

  return previous;
}
