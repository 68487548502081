import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAppSelector from '../hooks/useAppSelector';

function LoginRedirect() {
  return <Redirect to="/login" />;
}

function PrivateRoute({ component, exact, path }: RouteProps) {
  const isAuth = useAppSelector(({ auth }) => !!auth.refreshToken);

  return (
    <Route
      exact={exact}
      path={path}
      component={isAuth ? component : LoginRedirect}
    />
  );
}

export default PrivateRoute;
