import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import useUpdateChatParticipant from '../services/queries/chat-room/useUpdateChatParticipant';
import useChatParticipants, {
  CHAT_PARTICIPANTS_QUERY_KEY,
} from '../services/queries/useChatParticipants';

interface IUseUnreadMessageProps {
  currentUserId: string;
  accountId: string;
  lastMessageTimeStamp?: string;
  showUnreadIcon?: boolean;
}

export const useUnreadMessage = ({
  currentUserId,
  accountId,
  lastMessageTimeStamp,
  showUnreadIcon,
}: IUseUnreadMessageProps) => {
  const queryClient = useQueryClient();

  const { data: participants } = useChatParticipants(accountId, {
    enabled: !!accountId && !!showUnreadIcon,
    staleTime: 60000,
  });

  const { mutate: updateParticipant } = useUpdateChatParticipant(
    accountId,
    currentUserId,
    {
      onSuccess() {
        queryClient.invalidateQueries([CHAT_PARTICIPANTS_QUERY_KEY, accountId]);
      },
    },
  );

  const lastSeenUser = useMemo(() => (
    participants?.data.results.find((user) => user.user.id === currentUserId)
  ), [currentUserId, participants?.data.results]);

  const hasUnreadMessage = useMemo(() => {
    if (!lastMessageTimeStamp || !participants?.data) return false;
    if (!lastSeenUser) return true;
    if (lastMessageTimeStamp > lastSeenUser.lastSeenAt) return true;
    return false;
  }, [lastMessageTimeStamp, participants?.data, lastSeenUser]);

  const sendParticipantLastSeenAt = useCallback(() => {
    updateParticipant({ lastSeenAt: DateTime.utc().toString() });
  }, [updateParticipant]);

  return { sendParticipantLastSeenAt, hasUnreadMessage };
};
