export class ErrorFactory {
  private ErrorMapper: { [index: number]: string } = {
    500: 'A server error occurred. Please, contact the support',
    403: "You don't have permission",
    404: 'Not found!',
  };

  buildErrorMessage(statusError?: number): string {
    return this.ErrorMapper[statusError || 500] || this.ErrorMapper[500];
  }
}
