import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './services/slices/auth';
import chat from './services/slices/chat';
import trackerboard from './services/slices/trackerboard';
import me from './services/slices/me';

export const reducers = combineReducers({
  auth,
  chat,
  trackerboard,
  me,
});

export const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
  },
  reducers,
);

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }),
    preloadedState,
  });
}

const store = setupStore();

export const persistor = persistStore(store);

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;

export default store;
