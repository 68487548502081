/* tslint:disable */
/* eslint-disable */
/**
 * Sunrise Telephony API
 * Telephony management API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateMeetingDto
 */
export interface CreateMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    'queueEntryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMeetingDto
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    'startTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMeetingDto
     */
    'type': number;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface MeetingDto
 */
export interface MeetingDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    'meetingId': string;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignatureDto
 */
export interface SignatureDto {
    /**
     * 
     * @type {string}
     * @memberof SignatureDto
     */
    'signature': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeetingApi - axios parameter creator
 * @export
 */
export const MeetingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsControllerCreate: async (createMeetingDto: CreateMeetingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMeetingDto' is not null or undefined
            assertParamExists('meetingsControllerCreate', 'createMeetingDto', createMeetingDto)
            const localVarPath = `/v1/meeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsControllerGenerateMeetingSignature: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meetingsControllerGenerateMeetingSignature', 'id', id)
            const localVarPath = `/v1/meeting/{id}/signature`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsControllerGetMeetingInfoByQueueEntryId: async (queueEntryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueEntryId' is not null or undefined
            assertParamExists('meetingsControllerGetMeetingInfoByQueueEntryId', 'queueEntryId', queueEntryId)
            const localVarPath = `/v1/meeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queueEntryId !== undefined) {
                localVarQueryParameter['queueEntryId'] = queueEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingApi - functional programming interface
 * @export
 */
export const MeetingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeetingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meetingsControllerCreate(createMeetingDto: CreateMeetingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meetingsControllerCreate(createMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meetingsControllerGenerateMeetingSignature(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignatureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meetingsControllerGenerateMeetingSignature(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} queueEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meetingsControllerGetMeetingInfoByQueueEntryId(queueEntryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meetingsControllerGetMeetingInfoByQueueEntryId(queueEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeetingApi - factory interface
 * @export
 */
export const MeetingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeetingApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsControllerCreate(createMeetingDto: CreateMeetingDto, options?: any): AxiosPromise<MeetingDto> {
            return localVarFp.meetingsControllerCreate(createMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsControllerGenerateMeetingSignature(id: string, options?: any): AxiosPromise<SignatureDto> {
            return localVarFp.meetingsControllerGenerateMeetingSignature(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsControllerGetMeetingInfoByQueueEntryId(queueEntryId: string, options?: any): AxiosPromise<MeetingDto> {
            return localVarFp.meetingsControllerGetMeetingInfoByQueueEntryId(queueEntryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeetingApi - object-oriented interface
 * @export
 * @class MeetingApi
 * @extends {BaseAPI}
 */
export class MeetingApi extends BaseAPI {
    /**
     * 
     * @param {CreateMeetingDto} createMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public meetingsControllerCreate(createMeetingDto: CreateMeetingDto, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).meetingsControllerCreate(createMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public meetingsControllerGenerateMeetingSignature(id: string, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).meetingsControllerGenerateMeetingSignature(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public meetingsControllerGetMeetingInfoByQueueEntryId(queueEntryId: string, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).meetingsControllerGetMeetingInfoByQueueEntryId(queueEntryId, options).then((request) => request(this.axios, this.basePath));
    }
}


