import { useCallback, useEffect, useRef } from 'react';
import openSocket, { Socket } from 'socket.io-client';

import useAppSelector from './useAppSelector';
import refreshTokenManager from '../services/api/refreshTokenManager';

const useSocket = (uri: string, path: string, enabled = true) => {
  const { accessToken } = useAppSelector((state) => state.auth);

  const socket = useRef<Socket>(
    openSocket(uri, { path, autoConnect: false, auth: { accessToken } }),
  );

  const updateConnection = useCallback((newToken?: string | null) => {
    if (newToken) {
      socket.current.auth = { accessToken: newToken };

      if (enabled && !socket.current.connected) {
        socket.current.connect();
      }

      if (!enabled && socket.current.connected) {
        socket.current.disconnect();
      }
    } else if (socket.current.connected) {
      socket.current.disconnect();
    }
  }, [enabled]);

  useEffect(() => {
    updateConnection(accessToken);
  }, [accessToken, updateConnection]);

  useEffect(() => {
    const onDisconnect = async (reason: Socket.DisconnectReason) => {
      if (reason === 'io server disconnect') {
        if (refreshTokenManager.isLocked()) {
          await refreshTokenManager.waitForUnlock();
          updateConnection(accessToken);
          return;
        }

        refreshTokenManager.runRefreshToken(async (data) => {
          updateConnection(data.accessToken);
        });
      }
    };

    socket.current.on('disconnect', onDisconnect);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      socket.current.off('disconnect', onDisconnect);
    };
  }, [accessToken, updateConnection]);

  return socket.current;
};

export default useSocket;
