/* eslint-disable no-underscore-dangle */
const {
  API_BASE_URL,
  CHECK_IN_URL,
  ZOOM_SDK_KEY,
  MIXPANEL_TOKEN,
  MIXPANEL_URL,
  MIXPANEL_ENABLED,
  SENTRY_ENV,
} = window._CONFIG;

export const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || API_BASE_URL,
  CHECK_IN_URL: process.env.REACT_APP_CHECK_IN_URL || CHECK_IN_URL,
  ZOOM_SDK_KEY: process.env.REACT_APP_ZOOM_SDK_KEY || ZOOM_SDK_KEY,
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || MIXPANEL_TOKEN,
  MIXPANEL_URL: process.env.REACT_APP_MIXPANEL_URL || MIXPANEL_URL,
  MIXPANEL_ENABLED: process.env.MIXPANEL_ENABLED || MIXPANEL_ENABLED,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV || SENTRY_ENV,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
};
