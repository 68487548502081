import { useCallback, useContext } from 'react';
import { NotificationContext } from '../contexts/notification/NotificationProvider';
import { INotificationProps, NotificationPermissionEnum } from '../contexts/notification/types';

export const useNotification = () => {
  const {
    permission,
    notificationQueue,
    addNotification,
  } = useContext(NotificationContext);

  const sendNotification = useCallback((
    id: string,
    title: string,
    options?: INotificationProps,
    openUrl?: string,
  ) => {
    if (permission !== NotificationPermissionEnum.GRANTED) {
      return;
    }

    const [item] = notificationQueue.filter(({ id: _id }) => _id === id);

    if (!item) addNotification(id, title, options, openUrl);
  }, [addNotification, notificationQueue, permission]);

  return { sendNotification };
};
