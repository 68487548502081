import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { chatApiClient } from '../api';
import { ParticipantsResultDto } from '../api/chat-api';

type UseChatParticipantsOptions = UseQueryOptions<
AxiosResponse<ParticipantsResultDto>,
AxiosError
>;

export const CHAT_PARTICIPANTS_QUERY_KEY = 'chatParticipants';

const useChatParticipants = (accountId: string, options: UseChatParticipantsOptions) => (
  useQuery<
  AxiosResponse<ParticipantsResultDto>,
  AxiosError
  >(
    [CHAT_PARTICIPANTS_QUERY_KEY, accountId],
    () => chatApiClient.participantControllerFindAll(accountId),
    options,
  )
);

export default useChatParticipants;
