import {
  keyBy,
  orderBy,
  uniqBy,
  isEmpty,
} from 'lodash';

import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useQueryClient } from 'react-query';

import useAppSelector from '../../../../../../hooks/useAppSelector';
import useAppDispatch from '../../../../../../hooks/useAppDispatch';
import useSocket from '../../../../../../hooks/useSocket';
import useSocketEvent from '../../../../../../hooks/useSocketEvent';

import {
  MessagePaginatedItemDto,
  AttachmentDto,
} from '../../../../../../services/api/chat-api';

import { baseURL } from '../../../../../../services/api/client';
import { setCurrentMayaEntrypoint } from '../../../../../../services/slices/chat';

import useChatMessages, {
  CHAT_MESSAGES_QUERY_KEY,
} from '../../../../../../services/queries/useChatMessages';

import useChatParticipants, {
  CHAT_PARTICIPANTS_QUERY_KEY,
} from '../../../../../../services/queries/useChatParticipants';

import { MESSAGES_PER_PAGE } from '../../components/Chat/Chat.constants';

import {
  ChatContextProviderProps,
  IChatContext,
  NewMessage,
} from './ChatContext';

import usePrevious from '../../../../../../hooks/usePrevious';
import { useUnreadMessage } from '../../../../../../hooks/useUnreadMessage';

export const ChatContext = createContext<IChatContext>({} as IChatContext);

export enum LoadMessageState { OLD_MESSAGE, NEW_MESSAGE }

export function ChatContextProvider({ children }: ChatContextProviderProps) {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const loggedUserId = useAppSelector((state) => state.me.id);

  const currentAccountId = useAppSelector((state) => (
    state.trackerboard.activeQueueEntry?.account?.id
  ));

  const previousAccountId = usePrevious(currentAccountId);

  const { sendParticipantLastSeenAt } = useUnreadMessage({
    accountId: previousAccountId!,
    currentUserId: loggedUserId!,
  });

  const { currentMayaEntrypoint } = useAppSelector((state) => state.chat);

  const [messages, setMessages] = useState<Array<MessagePaginatedItemDto>>([]);

  const [oldAccountId, setOldAccountId] = useState<string>();
  const [before, setBefore] = useState<string>();
  const [activeAttachment, setActiveAttachment] = useState<AttachmentDto>();

  const [loadMessageState, setLoadMessageState] = useState(LoadMessageState.OLD_MESSAGE);

  const socket = useSocket(baseURL, '/chat/socket.io');

  const { data: participantsData } = useChatParticipants(currentAccountId!, {
    enabled: !!currentAccountId,
  });

  const chatParticipants = useMemo(() => (
    keyBy(participantsData?.data?.results, (p) => p.user?.id)
  ), [participantsData]);

  const isCurrentUserParticipantInCurrentChat = useMemo(() => (
    loggedUserId && Object.prototype.hasOwnProperty.call(chatParticipants, loggedUserId)
  ), [chatParticipants, loggedUserId]);

  const orderMessages = useCallback((unorderedMessages: Array<MessagePaginatedItemDto>) => (
    uniqBy(orderBy(unorderedMessages, 'timestamp').reverse(), 'id').reverse()
  ), []);

  const handleSocketConnection = useCallback(() => {
    setBefore(new Date().toISOString());
    if (!currentAccountId) return;
    socket.emit('joinRoom', { accountId: currentAccountId });
  }, [currentAccountId, socket]);

  const saveMessageFromServer = useCallback((...receivedMessages: MessagePaginatedItemDto[]) => {
    setLoadMessageState(LoadMessageState.NEW_MESSAGE);

    setMessages((oldMessagesList) => ([...oldMessagesList, ...receivedMessages]));

    const currentEntrypointMessage = receivedMessages.find((message) => (
      message.botEntrypoint?.id === currentMayaEntrypoint?.entrypoint.id
    ));

    const shouldUpdateEntrypoint = (
      currentEntrypointMessage?.botEntrypoint && !isEmpty(currentEntrypointMessage.botEntrypoint)
    );

    if (shouldUpdateEntrypoint) {
      dispatch(setCurrentMayaEntrypoint({
        entrypoint: currentEntrypointMessage.botEntrypoint!,
        accountId: currentEntrypointMessage.accountId,
        userId: currentEntrypointMessage.userId,
      }));
    }
  }, [currentMayaEntrypoint?.entrypoint.id, dispatch]);

  const sendMessageToServer = useCallback((newMessage: NewMessage) => {
    setMessages((oldMessagesList) => ([
      ...oldMessagesList,
      { ...newMessage, createdAt: '', updatedAt: '' },
    ]));

    socket.emit('message', newMessage);

    if (!isCurrentUserParticipantInCurrentChat) {
      queryClient.invalidateQueries([CHAT_PARTICIPANTS_QUERY_KEY, currentAccountId]);
    }
  }, [currentAccountId, isCurrentUserParticipantInCurrentChat, queryClient, socket]);

  useSocketEvent(socket, 'connect', handleSocketConnection, [socket, handleSocketConnection]);
  useSocketEvent(socket, 'message', saveMessageFromServer, [socket, saveMessageFromServer]);

  const {
    hasNextPage,
    fetchNextPage,
  } = useChatMessages({
    offset: 0,
    limit: MESSAGES_PER_PAGE,
    accountId: oldAccountId!,
    before,
    options: {
      enabled: !!currentAccountId && !!before,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ data }) => {
        if (data.results.length >= data.limit) {
          return data.offset + data.limit;
        }
        return undefined;
      },
      onSuccess: (data) => {
        setMessages((oldMessagesList) => ([
          ...oldMessagesList,
          ...data.pages.flatMap((page) => page.data.results),
        ]));
      },
    },
  });

  const loadMore = useCallback(() => {
    fetchNextPage();
    setLoadMessageState(LoadMessageState.OLD_MESSAGE);
  }, [fetchNextPage]);

  const orderedMessages = useMemo(() => orderMessages(messages), [messages, orderMessages]);

  const value = useMemo<IChatContext>(
    () => ({
      messages: orderedMessages,
      chatParticipants,
      sendMessageToServer,
      currentAccountId,
      loggedUserId,
      hasNextPage,
      loadMore,
      activeAttachment,
      setActiveAttachment,
      loadMessageState,
    }),
    [
      chatParticipants,
      currentAccountId,
      loggedUserId,
      orderedMessages,
      sendMessageToServer,
      hasNextPage,
      loadMore,
      activeAttachment,
      loadMessageState,
    ],
  );

  useEffect(() => {
    if (socket && currentAccountId) {
      setMessages([]);
      setLoadMessageState(LoadMessageState.OLD_MESSAGE);
      setBefore(new Date().toISOString());

      setOldAccountId((oldId) => {
        if (!oldId) {
          socket.emit('joinRoom', { accountId: currentAccountId });
        } else if (currentAccountId !== oldId) {
          socket.emit('leaveRoom', { accountId: oldId });
          socket.emit('joinRoom', { accountId: currentAccountId });
        }
        return currentAccountId;
      });
    }
  }, [socket, currentAccountId]);

  useEffect(() => {
    queryClient.invalidateQueries([{ CHAT_MESSAGES_QUERY_KEY, accountId: currentAccountId }]);
  }, [currentAccountId, queryClient]);

  useEffect(() => {
    if (previousAccountId && loggedUserId) {
      sendParticipantLastSeenAt();
    }
  }, [previousAccountId, loggedUserId, sendParticipantLastSeenAt]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
