import { ThemeProvider } from 'styled-components';
import theme from '../../styles/theme';
import * as S from './styles';

export function ErrorFallback() {
  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <S.Message>An error has occurred</S.Message>
        <S.Message>Try reloading the page later or return to the previous page</S.Message>
      </S.Wrapper>
    </ThemeProvider>
  );
}
