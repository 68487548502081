import { CommunityMemberType, UserUserTypeEnum } from '../services/api/user-api/api';

export const UserDropDownoptions = [
  { value: UserUserTypeEnum.Admin, name: 'Admin Health guide' },
  { value: UserUserTypeEnum.Coach, name: 'Health guide' },
  { value: UserUserTypeEnum.Physician, name: 'Physician' },
];

export const CommunityMemberTypeOptions = [
  { value: CommunityMemberType.HelloFamTeam, name: 'HelloFam Team' },
  { value: CommunityMemberType.KeyOpinionLeader, name: 'Key Opinion Leader' },
  { value: CommunityMemberType.Member, name: 'Member' },
];

export const TABLE_DEFAULT_LIMIT = 10;

export const USER_SYSTEM_ID: string = '00000000-0000-0000-0000-000000000000';

export enum QueueTab {
  Queue = 'Queue',
  Recent = 'Recent',
  Starred = 'Starred',
  AllGroups = 'All Groups',
  PastPatients = 'Past Patients',
}
