import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  margin-top: calc(100vh - 50vh);
`;

export const Message = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.default};
    font-family: ${theme.fonts.family.default};
    font-size: 1rem;
  `}
`;
