import { createContext } from 'react';
import { EmbeddedClient } from '@zoom/meetingsdk/embedded';

export type ZoomClient = typeof EmbeddedClient;

export type ZoomContextState = {
  client?: ZoomClient;
  initZoomClient: () => Promise<ZoomClient | undefined>;
};

export const ZoomContext = createContext<ZoomContextState>({
  initZoomClient: () => Promise.resolve(undefined),
});
