import { QueueEntryDtoStatusEnum } from '../../../../../services/api/queue-api';

export const OPEN_CHATS_QUEUE_ENTRY_STATUS: QueueEntryDtoStatusEnum[] = [
  QueueEntryDtoStatusEnum.InProgress,
  QueueEntryDtoStatusEnum.Assigned,
];

export const SCHEDULED_ASSIGNED_QUEUE_ENTRY_STATUS: QueueEntryDtoStatusEnum[] = [
  QueueEntryDtoStatusEnum.InProgress,
  QueueEntryDtoStatusEnum.Assigned,
];

export const SCHEDULED_NOT_ASSIGNED_QUEUE_ENTRY_STATUS: QueueEntryDtoStatusEnum[] = [
  QueueEntryDtoStatusEnum.Scheduled,
  QueueEntryDtoStatusEnum.Pending,
];

export const PHYSICIAN_TODAY_QUEUE_ENTRY_STATUS: QueueEntryDtoStatusEnum[] = [
  QueueEntryDtoStatusEnum.InProgress,
  QueueEntryDtoStatusEnum.Assigned,
  QueueEntryDtoStatusEnum.Pending,
  QueueEntryDtoStatusEnum.Scheduled,
];
