/* eslint-disable no-debugger */
import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { chatApiClient } from '../api';
import { MessagePaginatedResultDto } from '../api/chat-api';
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setCurrentMayaEntrypoint } from '../slices/chat';

type UseChatMessagesOptions = UseInfiniteQueryOptions<
AxiosResponse<MessagePaginatedResultDto>,
AxiosError
>;

type UseChatMessageArgs = {
  limit: number;
  offset: number;
  accountId: string;
  after?: string;
  before?: string;
  options?: UseChatMessagesOptions;
};

export const CHAT_MESSAGES_QUERY_KEY = 'chatMessages';

const useChatMessages = ({
  limit,
  accountId,
  after,
  before,
  options,
}: UseChatMessageArgs) => {
  const dispatch = useAppDispatch();
  const { currentMayaEntrypoint } = useAppSelector((state) => state.chat);

  return useInfiniteQuery<AxiosResponse<MessagePaginatedResultDto>, AxiosError>(
    [{
      CHAT_MESSAGES_QUERY_KEY, accountId, before, after,
    }],
    async ({ pageParam = 0 }) => {
      const result = await chatApiClient.messageControllerFindAll(
        limit,
        pageParam,
        accountId,
        after,
        before,
      );
      return result;
    },
    {
      ...options,
      onSuccess(data) {
        if (currentMayaEntrypoint) {
          const messageWithCurrentEndpoint = data.pages
            .flatMap((page) => page.data.results)
            .find((message) => message.botEntrypoint?.id === currentMayaEntrypoint.entrypoint.id);

          if (messageWithCurrentEndpoint?.botEntrypoint) {
            dispatch(setCurrentMayaEntrypoint({
              accountId: currentMayaEntrypoint.accountId,
              userId: currentMayaEntrypoint.userId,
              entrypoint: messageWithCurrentEndpoint.botEntrypoint,
            }));
          }
        }

        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
    },
  );
};

export default useChatMessages;
