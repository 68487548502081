import store, { persistor } from '../store';

import { signOut } from '../services/slices/auth';
import { clearActiveQueueEntry } from '../services/slices/trackerboard';
import { clearMe } from '../services/slices/me';
import { queryClient } from '../services/api/queryClient';

export default async function logout() {
  store.dispatch(signOut());
  store.dispatch(clearMe());
  store.dispatch(clearActiveQueueEntry());
  await persistor.purge();
  await queryClient.cancelQueries();
  await queryClient.cancelMutations();
  queryClient.clear();
}
