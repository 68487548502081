import { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import useSentryIdentity from '../hooks/useSentryIdentity';
import Loading from '../components/General/Loading';
import routes from './Routes';

// loading component to fallback other components
// const Loading = <Loading />;

// to add another router you need to put and config all your routes in routes.js
function Routes() {
  useSentryIdentity();

  return (
    <Router>
      <Suspense
        fallback={<Loading height={80} width={80} />}
      >
        <Switch>
          {routes.map((route) => (
            <route.route
              key={+route}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default Routes;
