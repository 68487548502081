import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { BrowserTracing } from '@sentry/tracing';

// eslint-disable-next-line import/no-named-as-default
import App from './App';
import { config } from './config';

const debug = process.env.NODE_ENV !== 'production';

mixpanel.init(config.MIXPANEL_TOKEN, { debug });
if (config.MIXPANEL_ENABLED !== 'true') {
  mixpanel.disable();
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://92f8991f2f044199909b000fc6ad4f8e@o1174277.ingest.sentry.io/6273177',
    integrations: [new BrowserTracing()],
    environment: config.SENTRY_ENV,
    release: config.SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
