import { AxiosError } from 'axios';
import { Mutex } from 'async-mutex';

import { AuthApiFactory, AuthenticationResponseDto } from './user-api';

import { setAuthentication } from '../slices/auth';

import store from '../../store';
import { config } from '../../config';
import logout from '../../utils/logout';

const baseURL = config.API_BASE_URL;

export const authApiClient = AuthApiFactory(undefined, baseURL);

class RefreshTokenManager {
  private refreshTokenLock: Mutex;

  constructor() {
    this.refreshTokenLock = new Mutex();
  }

  isLocked() {
    return this.refreshTokenLock.isLocked();
  }

  waitForUnlock() {
    return this.refreshTokenLock.waitForUnlock();
  }

  runRefreshToken<T>(
    callback: (authRes: AuthenticationResponseDto) => Promise<T>,
  ): Promise<T> {
    return this.refreshTokenLock.runExclusive(async () => {
      const { auth } = store.getState();

      try {
        const response = await authApiClient.authControllerRefresh(
          `Bearer ${auth.refreshToken}`,
        );
        const { accessToken, refreshToken } = response.data;

        store.dispatch(setAuthentication({ accessToken, refreshToken }));
        const result = await callback(response.data);
        return result;
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError?.response?.status === 401) {
          logout();
        }

        return Promise.reject();
      }
    });
  }
}

const refreshTokenManager = new RefreshTokenManager();

export default refreshTokenManager;
