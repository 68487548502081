import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAppSelector from '../hooks/useAppSelector';

function HomeRedirect() {
  return <Redirect to="/home/trackerboard" />;
}

function PublicRoute({ component, exact, path }: RouteProps) {
  const isAuth = useAppSelector(({ auth }) => !!auth.refreshToken);

  return (
    <Route
      exact={exact}
      path={path}
      component={isAuth ? HomeRedirect : component}
    />
  );
}

export default PublicRoute;
