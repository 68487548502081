import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { chatApiClient } from '../../api';
import { UpdateParticipantDto } from '../../api/chat-api';

type UseUpdateChatParticipantOptions = UseMutationOptions<
AxiosResponse<UpdateParticipantDto>,
AxiosError,
UpdateParticipantDto
>;
export const UPDATE_CHAT_PARTICIPANT_QUERY_KEY = 'update-chat-participant';

const useUpdateChatParticipant = (
  accountId: string,
  userId: string,
  options: UseUpdateChatParticipantOptions,
) => useMutation<
AxiosResponse<UpdateParticipantDto>,
AxiosError,
UpdateParticipantDto
>(
  [UPDATE_CHAT_PARTICIPANT_QUERY_KEY, accountId, userId],
  async (data) => chatApiClient.participantControllerUpdate(accountId, userId, data),
  options,
);

export default useUpdateChatParticipant;
