import styled, { css, keyframes } from 'styled-components';
import { convertColorToFilter } from '../../../utils/colors';
import { ILoadingProps } from './Loading';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.img<ILoadingProps>`
  ${({
    theme, color, height, width,
  }) => css`
    width: ${width || 20}px;
    height: ${height || 20}px;
    animation: ${rotate} 0.8s linear infinite;
    filter: ${convertColorToFilter(color || theme.colors.primary).color};
  `}
`;
