import CssFilterConverter from 'css-filter-converter';

export const convertColorToFilter = (color: string) => {
  if (color.includes('#')) {
    return CssFilterConverter.hexToFilter(color);
  }

  if (color.includes('rgb')) {
    return CssFilterConverter.rgbToFilter(color);
  }

  if (color.includes('hsl')) {
    return CssFilterConverter.hslToFilter(color);
  }

  return CssFilterConverter.keywordToFilter(color);
};
