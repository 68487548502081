import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { queueApiClient } from '../api';
import { QueueEntryPaginatedResultDto } from '../api/queue-api';

type UseQueueEntriesOptions = UseInfiniteQueryOptions<
AxiosResponse<QueueEntryPaginatedResultDto>,
AxiosError
>;
export type QueueEntryStatus =
  | 'pending'
  | 'scheduled'
  | 'assigned'
  | 'in_progress'
  | 'completed';

type IQueueType = 'general' | 'scheduled';

export type QueueEntriesArgs = {
  limit: number;
  offset: number;
  order?: string;
  status?: Array<QueueEntryStatus>;
  updatedAfter?: string;
  updatedBefore?: string;
  scheduledForStartAfter?: string;
  scheduledForStartBefore?: string;
  queueType?: IQueueType;
  coachId?: string;
  accountId?: string;
  physicianId?: string;
  lastMessageTimestamp?: string;
  hasNoCoach?: boolean;
};

export const queueEntriesList = 'queue-entries';

const useQueueEntries = (
  {
    limit,
    offset,
    order,
    status,
    updatedAfter,
    updatedBefore,
    scheduledForStartAfter,
    scheduledForStartBefore,
    queueType,
    coachId,
    physicianId,
    lastMessageTimestamp,
    accountId,
    hasNoCoach,
  }: QueueEntriesArgs,
  options?: UseQueueEntriesOptions,
) => useInfiniteQuery<AxiosResponse<QueueEntryPaginatedResultDto>, AxiosError>(
  [queueEntriesList, limit, offset, order, status],
  async ({ pageParam = 0 }) => {
    const result = await queueApiClient.queueEntryControllerFindAll(
      limit,
      pageParam,
      order,
      status,
      updatedAfter,
      updatedBefore,
      scheduledForStartAfter,
      scheduledForStartBefore,
      queueType,
      coachId,
      physicianId,
      lastMessageTimestamp,
      accountId,
      hasNoCoach,
    );
    return result;
  },
  options,
);

export default useQueueEntries;
