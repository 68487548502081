import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import useAppSelector from './useAppSelector';

export default function useSentryIdentity() {
  const user = useAppSelector((state) => state.me);

  useEffect(() => {
    if (user && user.id) {
      Sentry.setUser({
        id: user.id,
        type: user.userType,
        name: `${user.firstName} ${user.lastName}`,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);
}
