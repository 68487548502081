import { useEffect, useRef, DependencyList } from 'react';
import { Socket } from 'socket.io-client';

type CallbackFunction = (...args: any[]) => void;

const useSocketEvent = (
  socket: Socket | undefined,
  eventName: string,
  callback: CallbackFunction,
  deps: DependencyList,
) => {
  const callbackRef = useRef<CallbackFunction>();

  useEffect(() => {
    callbackRef.current = callback;
    // eslint-disable-next-line
  }, deps);

  useEffect(() => {
    if (!socket || !eventName) {
      return undefined;
    }

    const listener = (...args: any[]) => {
      if (callbackRef.current) {
        callbackRef.current(...args);
      }
    };

    socket.on(eventName, listener);

    return () => {
      socket.off(eventName, listener);
    };
  }, [socket, eventName, socket?.active, socket?.connected]);
};

export default useSocketEvent;
