/* eslint-disable @typescript-eslint/return-await */
import axios, { AxiosError } from 'axios';

import store from '../../store';
import { AuthApiFactory } from './user-api';
import { config as appConfig } from '../../config';
import refreshTokenManager from './refreshTokenManager';
import logout from '../../utils/logout';

export const baseURL = appConfig.API_BASE_URL || '';

export const client = axios.create();
export const authlessClient = axios.create();
export const authApiClient = AuthApiFactory(undefined, baseURL, authlessClient);

client.interceptors.request.use(
  async config => {
    const { auth } = store.getState();
    const { accessToken } = auth;

    if (accessToken && config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  response => response,
  async err => {
    const originalRequest = err.config;

    if (err.response && err.response.status === 401 && !originalRequest.alreadyTried) {
      if (refreshTokenManager.isLocked()) {
        await refreshTokenManager.waitForUnlock();
        return client(originalRequest);
      }

      originalRequest.alreadyTried = true;

      try {
        return await refreshTokenManager.runRefreshToken(async () => {
          try {
            return await client(originalRequest);
          } catch (error) {
            const axiosError = error as AxiosError;

            if (axiosError?.response?.status === 401) {
              logout();
            }

            return Promise.reject(err);
          }
        });
      } catch (error) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  },
);
