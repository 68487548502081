import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { GlobalStyle } from './styles/GlobalStyle';
import store, { persistor } from './store';
import Routes from './routes';
import theme from './styles/theme';
import 'react-toastify/dist/ReactToastify.min.css';
import { ErrorFallback } from './utils/errorFallback';
import ZoomProvider from './contexts/zoom/ZoomProvider';
import NotificationProvider from './contexts/notification/NotificationProvider';
import { queryClient } from './services/api/queryClient';
import { QueueContextProvider } from './pages/Home/components/TrackBoard/contexts';
import { ChatContextProvider } from './pages/Home/components/CommunicationContainer/contexts/ChatContext';

export function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <ZoomProvider>
                <NotificationProvider>
                  <QueueContextProvider>
                    <ChatContextProvider>
                      <GlobalStyle />
                      <Routes />
                      <ReactQueryDevtools />
                    </ChatContextProvider>
                  </QueueContextProvider>
                </NotificationProvider>
              </ZoomProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
